import type { User } from '@ifixit/auth-sdk';
import type { CustomerAccessToken } from '@ifixit/shopify-storefront-client';
import Cookies from 'js-cookie';
import { z } from 'zod';

export function getStoredCustomerAccessToken({
   storeCode,
   user,
}: { storeCode: string; user: User }): CustomerAccessToken | null {
   const userId = user.id;
   const cartCookie = getCartCookie({ storeCode, userId });
   return cartCookie[storeCode]?.customerAccessToken ?? null;
}

export function clearStoredCustomerAccessToken({
   storeCode,
   user,
}: { storeCode: string; user: User }): void {
   const userId = user.id;
   const cartCookie = getCartCookie({ storeCode, userId });
   if (cartCookie[storeCode].customerAccessToken) {
      cartCookie[storeCode].customerAccessToken = null;
      setCartCookie({ cartCookie, userId });
   }
}

export function getStoredCartId({
   user,
   storeCode,
}: { user: User | null; storeCode: string }): string | null {
   const userId = user?.id ?? null;
   const cartCookie = getCartCookie({ storeCode, userId });
   return cartCookie[storeCode]?.cartId ?? null;
}

export function clearStoredCartId({
   storeCode,
   user,
}: { storeCode: string; user: User | null }): void {
   const userId = user?.id ?? null;
   const cartCookie = getCartCookie({ storeCode, userId });
   if (cartCookie[storeCode].cartId) {
      cartCookie[storeCode].cartId = null;
      setCartCookie({ cartCookie, userId });
   }
}

type StoreCodeMaybeUserId = {
   storeCode: string;
   userId: number | null;
};
type StoreCodeUserId = {
   storeCode: string;
   userId: number;
};

export function updateCartId({
   cartId,
   storeCode,
   userId,
}: { cartId: string } & StoreCodeMaybeUserId) {
   const cartCookie = getCartCookie({ storeCode, userId });
   cartCookie[storeCode].cartId = cartId;
   setCartCookie({ cartCookie, userId });
}

export function updateCustomerAccessToken({
   customerAccessToken,
   storeCode,
   userId,
}: { customerAccessToken: CustomerAccessToken } & StoreCodeUserId) {
   const cartCookie = getCartCookie({ storeCode, userId });
   cartCookie[storeCode].customerAccessToken = customerAccessToken;
   setCartCookie({ cartCookie, userId });
}

const CustomerAccessTokenSchema = z.object({
   accessToken: z.string(),
   expiresAt: z.string(),
});
const CartCookieSchema = z.record(
   z.string(),
   z.object({
      cartId: z.string().nullable(),
      customerAccessToken: CustomerAccessTokenSchema.nullable(),
   })
);
type CartCookie = z.infer<typeof CartCookieSchema>;

function getCartCookie({ storeCode, userId }: StoreCodeMaybeUserId) {
   const key = cartCookieKey({ userId });
   let rawCookie;
   try {
      rawCookie = JSON.parse(Cookies.get(key) ?? '');
   } catch {
      rawCookie = null;
   }
   const parsed = CartCookieSchema.safeParse(rawCookie);
   const existing = parsed.success ? parsed.data : {};
   if (!existing[storeCode]) {
      existing[storeCode] = {
         cartId: null,
         customerAccessToken: null,
      };
   }
   return existing;
}

function setCartCookie({ cartCookie, userId }: { cartCookie: CartCookie; userId: number | null }) {
   const key = cartCookieKey({ userId });
   const domain: string | undefined = ifixitDomain() ?? cominorDomain() ?? undefined;
   // Carts live for 10 days https://shopify.dev/docs/storefronts/headless/building-with-the-storefront-api/cart#limitations-and-considerations
   const expires = 10;
   Cookies.set(key, JSON.stringify(cartCookie), { domain, expires });
}

/**
 * Keep in sync with ShopifyCustomerService.php
 */
function cartCookieKey({ userId }: { userId: number | null }) {
   return `shopifyCart.user.${userId ?? 'anonymous'}`;
}

function cominorDomain() {
   return /\.cominor.com$/.test(window.location.hostname) ? '.cominor.com' : null;
}

function ifixitDomain() {
   return /\.ifixit.com$/.test(window.location.hostname) ? '.ifixit.com' : null;
}
