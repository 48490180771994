import {
   type AnalyticsItemsEvent,
   type CurrencyCode,
   convertCartLineItemsToAnalyticsItem,
   toUSD,
   trackAnalyticsCustomEvent,
} from '@ifixit/analytics';
import { AddToCartInput } from '../hooks/cart/use-add-to-cart';

const ADD_TO_CART_EVENT = 'Add to Cart';

export function trackAnalyticsShopifyAddToCart({ lines, analytics }: AddToCartInput) {
   const itemcodes =
      analytics.descriptor === 'Frequently Bought Together'
         ? `${analytics.currentItemcode}/${lines[0].itemcode}`
         : lines.map(line => line.itemcode).join(', ');
   const totalPriceUSD = lines.reduce((sum, line) => {
      const lineUSD = toUSD(Number(line.price.amount), line.price.currencyCode as CurrencyCode);
      const linePrice = line.quantity * lineUSD;
      return sum + linePrice;
   }, 0);
   trackAnalyticsCustomEvent({
      eventCategory: ADD_TO_CART_EVENT,
      eventAction: `${ADD_TO_CART_EVENT} - ${analytics.descriptor}`,
      eventName: itemcodes,
      eventValue: totalPriceUSD,
   });
}

export function convertAddToCartInputToAnalyticsItemEvent(
   input: AddToCartInput
): AnalyticsItemsEvent {
   return {
      items: convertCartLineItemsToAnalyticsItem(input.lines),
      value: input.lines.reduce((acc, item) => acc + Number(item.price.amount), 0),
      currency: input.lines[0]?.price.currencyCode,
      localeCode: input.analytics.localeCode,
   };
}
